import { TezosToolkit,
ContractAbstraction,
  OpKind,
  TransactionWalletOperation,
  Wallet,
  WalletOperation,
  WalletOperationBatch,
  WalletParamsWithKind } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';

const Tezos = new TezosToolkit('https://mainnet.api.tez.ie');

const wallet = new BeaconWallet({
  name: 'fx(party) live',
  preferredNetwork: 'mainnet',
});

Tezos.setWalletProvider(wallet);

export function getWallet(client) {
  wallet.client = client;
  return Tezos.wallet;
}
