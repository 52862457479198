import get from 'lodash/get';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TwitterIcon from '@mui/icons-material/Twitter';
import PaidIcon from '@mui/icons-material/Paid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ReactTimeAgo from 'react-time-ago';
import {
  formatTz,
  getPreviewImage,
  getArtistInfo,
  getPlatform,
  isTokenUpToDate,
  getSwapPrice,
  isBestPrice,
  getTokenLink,
  getUserInfo,
  fetchFxhashIssuer,
} from '../libs/utils';
import { EVENT_CATEGORY_MINT, EVENT_CATEGORY_MINTED, EVENT_CATEGORY_SWAP, EVENT_CATEGORY_SALE, EVENT_CATEGORY_OFFER, EVENT_CATEGORY_ACCEPTED, EVENTS_WITH_BUY_SUPPORT } from '../constants';
import BuyButton from './BuyButton';
import TokenDescriptionTooltip from './TokenDescriptionTooltip';
import useImage from '../hooks/use-image';

const EVENT_CATEGORY_TO_CHIP_PROPS = {
  [EVENT_CATEGORY_MINT]: { color: 'primary', variant: 'outlined' },
  [EVENT_CATEGORY_MINTED]: { color: 'primary', variant: 'outlined' },
  [EVENT_CATEGORY_SWAP]: { color: 'primary' },
  [EVENT_CATEGORY_OFFER]: { color: 'error', variant: 'outlined' },
  [EVENT_CATEGORY_ACCEPTED]: { color: 'error', variant: 'outlined' },
  [EVENT_CATEGORY_SALE]: { color: 'info', variant: 'outlined' },
};

function UserLink({ address, name, twitter }) {
  return (
    <>
      {address ? (
        <Typography variant="body2" component="strong" color="primary">
          <Link href={`https://www.fxhash.xyz/pkh/${address}`} target="_blank" rel="noopener noreferrer" color="inherit"
            sx={{
              fontSize: {
                mobile: '0.6em',
                tablet_short: '0.9em',
              },
            }}
          >
            {name}
          </Link>
        </Typography>
      ) : null}

      {twitter ? (
        <IconButton
          color="primary"
          size="small"
          href={`https://twitter.com/${twitter}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            ml: 0.15,
          }}
        >
          <TwitterIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </>
  );
}

function PreviewImage({ src, description, imageSize, event }) {
  const tokenLink = getTokenLink(event);
  const { status } = useImage(src);
  const size = imageSize === 'large' ? '140px' : '90px';
  const objectFit = imageSize === 'large' ? 'contain' : 'cover';
  const gentktitle = get(event, 'token.name');
  let content;

  if (!src || status === 'loading') {
    content = (
      <CircularProgress
        color="primary"
        size={14}
        thickness={5}
        sx={{
          mr: 2,
          ml: 2,
        }}
      />
    );
  } else if (status === 'failed') {
    content = 'N/A';
  } else {
    const img = (
      <img
        src={src}
        alt={description}
        loading="lazy"
        style={{
          width: size,
          height: size,
          objectFit: objectFit,
        }}
      />
    );

    if (get(event, 'token.name')) {
      content = (
        <Tooltip title={gentktitle} arrow placement="right">
          {img}
        </Tooltip>
      );
    } else {
      content = img;
    }
  }

  return (
    <TableCell
      className="fxPreview"
      sx={{
        order: 2,
        p: 0,
        width: size,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        pl: {
          mobile: 1,
          tablet_short: 0,
        },
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
          maxWidth: size,
          maxHeight: size,
          backgroundColor: '#101010',
          lineHeight: 0,
        }}
      >
        <Link href={tokenLink} target="_blank">
          {content}
        </Link>
      </Box>
    </TableCell>
  );
}

function Meta({ event }) {
  const artistInfo = getArtistInfo(event);
  const platform = getPlatform(event);
  const mime = get(event, 'token.mime_type');
  const editions = get(event, 'token.fx_collection_editions') || get(event, 'token.editions');
  const tokenName = get(event, 'token.name');
  const gentktitle = tokenName ? tokenName.toString() : '';
  const editionNo = tokenName ? tokenName.slice(tokenName.lastIndexOf('#') + 1) : '';
  const seller = getUserInfo(event, 'seller');
  const transactionType = event.isSecondarySwap ? 'LIST' : event.category;

  return (
  //console.log(fetchFxhashIssuer(get(event, 'token.token_id'))),
    //console.log(JSON.stringify(event, null, 3)),
    //console.log(get(event, 'token.token_id')),
    <TableCell className="fxMeta"
      sx={{
        order:4,
        width: {
          mobile: '100%',
          tablet_portrait: '100%',
          tablet_short: 'unset',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: {
            mobile: 'center',
            tablet_short: 'flex-start',
          },
          pt: {
            mobile: 1,
            tablet_short: 0,
          },
          pb: {
            mobile: 1,
            tablet_short: 0,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            pr: {
              mobile: 1,
              tablet_short: 3,
            },
            pl: {
              mobile: 1,
              tablet_short: 3,
            },
          }}
        >
          <Stack spacing={1}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: {
                  mobile: '0.6em',
                  tablet_short: '0.9em',
                },
              }}
            >
              {event.category === EVENT_CATEGORY_MINT || event.category === EVENT_CATEGORY_MINTED ? (
                <>
                  Minted by&nbsp;
                  <UserLink {...getUserInfo(event, 'buyer')} />
                </>
              ) : null}

              {event.category === EVENT_CATEGORY_SWAP ? (
                <>
                  {!event.isSecondarySwap && (
                    <>
                      By&nbsp;
                      <UserLink {...artistInfo} color="primary" />
                    </>
                  )}
                  Seller
                  &nbsp;
                  <UserLink {...getUserInfo(event, 'seller')} />
                </>
              ) : null}

              {event.category === EVENT_CATEGORY_OFFER ? (
                <>
                  Made by&nbsp;
                  <UserLink {...getUserInfo(event, 'buyer')} />
                </>
              ) : null}

              {event.category === EVENT_CATEGORY_SALE || event.category === EVENT_CATEGORY_ACCEPTED ? (
                <>
                  From&nbsp;
                  <UserLink {...getUserInfo(event, 'seller')} />
                  &nbsp;
                  <ArrowRightAltIcon />
                  &nbsp;
                  <UserLink {...getUserInfo(event, 'buyer')} />
                </>
              ) : null}

              <Typography
                variant={{
                  mobile: '0.6em',
                      tablet_short: "body2"
                    }}
                sx={{
                  ml: 1,
                }}
              >
                <ReactTimeAgo date={new Date(event.timestamp)} />
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: {
                  mobile: 'center',
                  tablet_short: 'flex-start',
                },
                alignItems: {
                  mobile: 'flex-start',
                  tablet_short: 'center',
                },
              }}
            >
              <Tooltip title={get(event, 'token.name')} arrow placement="top">
                <Chip
                  size="small"
                  label={transactionType}
                  {...EVENT_CATEGORY_TO_CHIP_PROPS[event.category]}
                  sx={{
                    mr: 1,
                  }}
                />
              </Tooltip>

              {mime ? (
                <Chip
                  size="small"
                  label={mime}
                  color="secondary"
                  variant="outlined"
                  sx={{
                    mr: 1,
                  }}
                />
              ) : null}

              {/*platform ? (
                <Chip
                  size="small"
                  label={platform}
                  color="secondary"
                  variant="outlined"
                  sx={{
                    mr: 1,
                  }}
                />
              ) : null*/}


              {editions ? (
                <Chip
                    size="small"
                    label={`${editions} Edition${editions > 1 ? 's' : ''}`}
                    color="secondary"
                    variant="outlined"
                    sx={{
                      mr: 1,
                    }}
                  />
              ) : null }

              {event.category === EVENT_CATEGORY_MINTED && editionNo !== '[WAITING TO BE SIGNED]' ? (
                <Chip
                  size="small"
                  label={`${editionNo == '[WAITING TO BE SIGNED]' ? '' : editions - +editionNo+' Unminted '}`}
                  color="error"
                  variant="outlined"
                  sx={{
                    mr: 1,
                  }}
                />
              ) : null }

              {event.category === EVENT_CATEGORY_SWAP && (
                <>
                  {event.isSecondarySwap ? (
                    <Chip label="Secondary" color="warning" variant="contained" size="small" />
                  ) : (
                    <Chip label="Primary" color="primary" variant="contained" size="small" />
                  )}
                </>
              )}

              {transactionType === 'SALE' ? (
                <>
                  <Chip label="Secondary" color="warning" variant="contained" size="small" />
                </>
              ) : null}
            </Box>
          </Stack>
        </Box>
      </Box>
    </TableCell>
  );
}

function Creator({ event }) {
  const artistInfo = getArtistInfo(event);

  return (
    <TableCell className="fxArtist"
      sx={{
        width: {
          mobile: '100%',
          tablet_portrait: '100%',
          tablet_short: 'unset',
        },
        order: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: {
            mobile: 'center',
            tablet_short: 'flex-start',
          },
          fontSize: {
            mobile: '0.6em',
            tablet_short: '0.9em',
          },
        }}
      >
        <Box
          sx={{
            pr: {
              mobile: 0,
              tablet_short: 3,
            },
            pl: {
              mobile: 1,
              tablet_short: 3,
            },
          }}
        >
          <Stack
            sx={{
              flexDirection: {
                mobile: 'row',
                tablet_short: 'column',
              },
              alignItems: {
                mobile: 'baseline',
                tablet_short: 'flex-start',
              },
            }}
          >
            <Box
              sx={{
                marginRight: {
                  mobile: 1,
                  tablet_short: 0,
                },
              }}
            >
              Artist
            </Box>
            <Box>
              <UserLink {...artistInfo} color="primary" />
            </Box>
          </Stack>
        </Box>
      </Box>
    </TableCell>
  );
}

function Action({ event }) {
  const tokenLink = getTokenLink(event);
  const showBuyButton = EVENTS_WITH_BUY_SUPPORT.includes(event.type);

  return (
    <TableCell
      className="fxActions"
      align="right"
      sx={{
        order: 3,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          pl: {
            mobile: 1,
            tablet_short: 3,
          },
          pr: {
            mobile: 1,
            tablet_short: 3,
          },
          height: {
            mobile: '100%',
            tablet_short: 'unset',
          },
        }}
      >
        {!isTokenUpToDate(event) && (
          <CircularProgress
            color="primary"
            size={14}
            thickness={5}
            sx={{
              mr: 2,
              ml: 2,
            }}
          />
        )}

        {event.category === EVENT_CATEGORY_SWAP && (
          <>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  pr: 3,
                  pl: 3,
                }}
              >
                <Stack>
                  <Box>
                    <Typography variant="body2" component="strong" color="primary">
                      {formatTz(getSwapPrice(event))}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" component="strong" color="darkGreen">
                      (${(get(event, 'quotes.usd') * (getSwapPrice(event) / 1000000)).toFixed(2)})
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
            {/*showBuyButton && <BuyButton event={event} />*/}
          </>
        )}

        {(event.category === EVENT_CATEGORY_OFFER || event.category === EVENT_CATEGORY_SALE || event.category === EVENT_CATEGORY_MINTED|| event.category === EVENT_CATEGORY_ACCEPTED) && (
          <>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  pl: {
                    mobile: 0,
                    tablet_short: 3,
                  },
                  pr: {
                    mobile: 1,
                    tablet_short: 3,
                  },
                }}
              >
                <Stack>
                  <Box>
                    <Typography variant="body2" component="strong" color="white">
                      {formatTz(event.price)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" component="strong" color="lightGray">
                      (${(get(event, 'quotes.usd') * (event.price / 1000000)).toFixed(2)})
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </>
        )}
        {tokenLink ? (
          <Button href={tokenLink} target="_blank" rel="noopener noreferrer" variant="contained" size="small" sx={{ ml: 2 }}>
              {event.isSecondarySwap ? (
                    <Typography variant="body2" component="strong" color="lightGray">
                      Buy
                    </Typography>
                  ) : (
                    <Typography variant="body2" component="strong" color="lightGray">
                      View
                    </Typography>
                  )}
          </Button>
        ) : null}
        {/*showBuyButton && <BuyButton event={event} />*/}
      </Box>
    </TableCell>
  );
}

function EventItem({ event, imageSize }) {
  const rowStyles = {};

  if (event.isNew) {
    rowStyles.backgroundColor = '#000000';
  }

  return (
    <TableRow style={rowStyles}
      sx={{
        display: {
          mobile: 'flex',
          tablet_portrait: 'flex',
          tablet_short: 'table-row',
        },
        mb: {
          mobile: 2,
          tablet_short: 0,
        },
        justifyContent: {
          mobile: 'space-between',
          tablet_short: 0,
        },
        flexWrap: 'wrap',
      }}
    >
      <PreviewImage
        src={getPreviewImage(event)}
        description={get(event, 'token.platform') === 'TYPED' ? get(event, 'token.description') : ''}
        imageSize={imageSize}
        event={event}
      />
      <Meta event={event} />
      <Creator event={event} />
      <Action event={event} />
    </TableRow>
  );
}

function Feed({ events, imageSize }) {
  let content;

  if (!events || !events.length) {
    content = (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '90vh',
        }}
      >
        It's pretty silent right now...
      </Box>
    );
  } else {
    content = (
      <TableContainer
        sx={{
          p: {
            mobile: 1,
            tablet_short: 4,
          },
          mt: '-10px',
        }}
      >
        <Table>
          <TableBody>
            {(events || []).map((event) => (
              <EventItem key={event.id} event={event} imageSize={imageSize} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <div className="Feed">
      <Toolbar />
      {content}
    </div>
  );
}

export default Feed;
