import React from 'react'
import p5 from 'p5'

class Sketch extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  Sketch = (p) => {

     p.setup = () => {
      p.createCanvas(window.innerWidth, 83);
      p.noFill();
      p.noLoop();
      p.strokeWeight(2);
     }

     p.draw = () => {
      p.push();
      // Use the random function to create a random color for the background
      p.background('#0f0f0f');

      // Use the random function to create a random p.color for the lines
      p.stroke('#505050');

      // Use the noise function to create random, flowing lines
      let xoff = 0;
      for (let x = 0; x <= p.width; x += 6) {
        let y = p.noise(xoff, p.frameCount * 0.01) * p.height;
        p.line(x, 0, x, y);
        xoff += 0.1;
      }

      // Use the random function to create a random p.color for the shapes
      p.fill('#212121');

      // Use the noise function to create random shapes
      xoff = 0;
      p.strokeWeight(3);
      for (let x = 0; x <= p.width; x += 20) {
        let y = p.noise(xoff, p.frameCount * 0.01) * p.height;
        let vsize = p.random(5, 20);
        p.ellipse(x, y, vsize, vsize);
        xoff += 0.1;
      }
      p.pop();
     }
  }

  componentDidMount() {
    this.myP5 = new p5(this.Sketch, this.myRef.current)
  }

  render() {
    return (
      <div className="generative" ref={this.myRef}>

      </div>
    )
  }
}

export default Sketch